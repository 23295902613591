/* You can add global styles to this file, and also import other style files */
@use './reset.scss';
@use './var.scss';
@use './theme.scss';
@use './fonts.scss';

/** tailwindcss **/
@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  height: 100%;
}

body {
  --mdc-filled-text-field-container-color: transparent;
  --mat-form-field-state-layer-color: transparent;
  --primary-color: #00bcd4;
  --primary-color-dark: #0097a7;

  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  box-sizing: border-box;
}

/** scroll-bar **/
* {
  scrollbar-width: auto;
  scrollbar-color: #e0e0e0 transparent;
}

*::-webkit-scrollbar {
  width: 14px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #e0e0e0;
  border-radius: 10px;
  border: 4px solid #ffffff;
}

.dark {
  /** scroll-bar **/
  * {
    scrollbar-width: auto;
    scrollbar-color: #616161 transparent;
  }

  *::-webkit-scrollbar {
    width: 14px;
  }

  *::-webkit-scrollbar-track {
    background: #616161;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #a8a8a8;
    border-radius: 10px;
    border: 4px solid #616161;
  }
}

.bg-gray-5 {
  /** scroll-bar **/
  scrollbar-width: auto;
  scrollbar-color: #e0e0e0 transparent;

  &::-webkit-scrollbar {
    width: 14px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #e0e0e0;
    border-radius: 10px;
    border: 4px solid #f4f4f4;
  }
}

// 用於selectable.directive.ts focus時的外框class
.font-outline::before,
.font-outline::after {
  outline-width: 1px !important;
}

// 日期選擇器高度
.mat-datepicker-content .mat-calendar {
  height: 365px !important;
}

// placeholder
::placeholder {
  color: #cccccc !important;
}

// draw-card用到的3d效果
.preserve-3d {
  transform-style: preserve-3d;
}
.backface-visibility {
  backface-visibility: hidden;
}
