@use '@angular/material' as mat;
@use 'angular-material-contrast-fix';

$primary: mat.define-palette(mat.$cyan-palette, 500);
$accent: mat.define-palette(mat.$cyan-palette, 700);
$tailwind-red-palette: (
  50: rgb(254 242 242),
  100: rgb(254 226 226),
  200: rgb(254 202 202),
  300: rgb(252 165 165),
  400: rgb(248 113 113),
  500: rgb(239 68 68),
  600: rgb(220 38 38),
  700: rgb(185 28 28),
  800: rgb(153 27 27),
  900: rgb(127 29 29),
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
  ),
);

$warn: mat.define-palette($tailwind-red-palette, 400);

$theme: mat.define-light-theme(
  (
    color: (
      primary: $primary,
      accent: $accent,
      warn: $warn,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);

body {
  @include mat.core();
  @include mat.core-theme($theme);
  @include mat.all-component-themes($theme);
  @include angular-material-contrast-fix.all-component-themes($theme);
}

/** mat-icon-button **/
@for $i from 6 through 24 {
  .mat-mdc-icon-button.size-#{$i} {
    font-size: $i * 0.125rem !important;
    mat-icon {
      font-size: $i * 0.125rem;
    }
  }
}

.mat-mdc-icon-button {
  vertical-align: middle;
  height: 2em !important;
  width: 2em !important;
  padding: 0.5em !important;

  .mat-mdc-button-touch-target {
    width: 2em;
    height: 2em;
  }

  mat-icon {
    width: 1em;
    height: 1em;

    svg {
      width: 1em;
      height: 1em;
    }
  }
}

// 不是圓的 mat-icon-button
.mat-mdc-icon-button.\!rounded {
  .mat-mdc-button-persistent-ripple {
    border-radius: 0.25rem /* 4px */ !important;
  }
}

/** mat-icon **/

/* 依照文字大小調整 icon */
mat-icon.size-base {
  font-size: 1em;
}

/** 預設的 mat-icon 大小 */
@for $i from 1 through 24 {
  mat-icon.size-#{$i} {
    font-size: $i * 0.25rem;
  }
}

mat-icon {
  vertical-align: middle;
  width: 1em !important;
  height: 1em !important;
}

* {
  /** mat-button-toggle **/
  --mat-standard-button-toggle-background-color: #eeeeee;
  --mat-standard-button-toggle-selected-state-background-color: #00bcd4;
  --mat-standard-button-toggle-selected-state-text-color: #ffffff;
  --mat-standard-button-toggle-divider-color: #dcdcdc;
  --mat-standard-button-toggle-text-color: #303030;
  --mat-standard-button-toggle-height: 38px;
  --mat-menu-item-hover-state-layer-color: rgba(0, 188, 212, 0.1);

  --mdc-filled-text-field-disabled-container-color: transparent;
  --mdc-filled-text-field-disabled-input-text-color: #000000;
  --mdc-filled-text-field-disabled-label-text-color: #000000;
  --mdc-filled-text-field-disabled-active-indicator-color: #000000;
  --mdc-filled-button-disabled-container-color: #e2e2e2;
  --mdc-filled-button-disabled-label-text-color: #303030;

  /** 手機版 mat-dialog 最大寬度 **/
  --mat-dialog-container-small-max-width: 90vw;

  /** mat-button-toggle **/
  --mat-standard-button-toggle-selected-state-background-color: #0097A7;
}

/** mat-snack-bar **/
.error-snackbar {
  white-space: pre-wrap;
  width: 400px;
}

/** mat-form-field **/
.mat-mdc-menu-panel.p-0 {
  .mat-mdc-menu-content {
    padding: 0;
  }
}

.mat-mdc-form-field-hint-wrapper,
.mat-mdc-form-field-error-wrapper {
  padding: 0 !important;
}

// mat-form-field 左邊的 padding
mat-form-field.tight {
  .mat-mdc-form-field-infix {
    padding-top: 24px !important;
    padding-bottom: 8px !important;
    min-height: 56px !important;
  }
  .mdc-text-field {
    padding: 0 !important;
  }
}

.mat-form-field-disabled {
  .mdc-line-ripple::before,
  .mdc-line-ripple::after {
    border-bottom-style: dashed;
  }
}

/** mat-button font-size */
.mat-mdc-button,
.mat-mdc-outlined-button,
.mat-mdc-unelevated-button {
  font-size: 14px !important;
}

[mat-icon-button] {
  mat-icon {
    vertical-align: top;
  }
}

/** mat-select */
.mat-mdc-select.size-12 {
  border-bottom: 1px solid #6e6e6e;

  &.mat-mdc-select-disabled {
    border-bottom: 1px dashed #9e9e9e;
    .mat-mdc-select-arrow {
      color: #9e9e9e;
    }
  }

  height: 48px;
  line-height: 48px;
  .mat-mdc-select-arrow-wrapper {
    width: 48px;
    .mat-mdc-select-arrow {
      width: 48px;
    }
  }
}

/** tailwind css 會蓋掉 expansion indicator 的顏色 */
.mat-expansion-indicator::after {
  border-color: #000000;
}

/** mat-expansion-panel */
button.text-ellipsis .mdc-button__label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  width: 100%;
}

/** mat-dialog 透明背景 **/
.transparent-dialog-panel .mat-mdc-dialog-surface {
  background-color: transparent !important;
  box-shadow: none !important;
}

/** mat-menu 選單 */
[mat-menu-item] .mat-mdc-menu-item-text {
  display: inline-flex;
  align-items: center;
  gap: 8px;
}

/** mat-button-toggle 不需要有勾 */
mat-button-toggle mat-pseudo-checkbox {
  display: none !important;
}


mat-slide-toggle,mat-checkbox{
  .mdc-form-field>label{
    font-size: 16px;
  }
}